<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="hub_admin_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(admin_user, index) in admin_users" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ admin_user.name }}</td>
              <td>{{ admin_user.email }}</td>
              <td>
                <a-tag color="#87d068" v-if="admin_user.is_active == 1">
                  Active
                </a-tag>
                <a-tag color="#f50" v-else>
                  Inactive
                </a-tag>
              </td>
              <td>
                <router-link :to="{ name: 'adminScenarioAdminHubs', params: { user_id: admin_user.id } }" class="btn btn-sm btn-success mr-1" tag="a">
                  <i class="fa fa-tasks mr-1"/>Assign Hubs
                </router-link>
                <router-link :to="{ name: 'adminScenarioAdminHubsView', params: { user_id: admin_user.id } }" class="btn btn-sm btn-info mr-1" tag="a">
                  <i class="fa fa-eye mr-1"/>View Assign Hubs
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="admin_users.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      admin_users: [],
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getHubAdmins()
  },
  methods: {
    getHubAdmins() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/admin-scenario/admins')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.admin_users = response.data.admin_users
          this.hubs = response.data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
